import { Controller } from "@hotwired/stimulus";
import { isStringTrue } from "../../../utils/misc";
import { handleHttpResponse } from "../../../utils/http_utils";

export default class extends Controller {
  salesUpdated(e) {
    const { isAttachmentTypeChanged, accountableId } = e.currentTarget.dataset;

    handleHttpResponse({
      payload: e.detail[0],
      onSuccess: (response) => {
        if (isStringTrue(isAttachmentTypeChanged)) {
          document.dispatchEvent(
            new CustomEvent("accountable:typeChanged", { detail: { deletedInvoiceId: accountableId } })
          );
        } else {
          const salesInvoices = JSON.parse(response).sales_invoices;
          document.dispatchEvent(new CustomEvent("salesInvoice:updated", { detail: { salesInvoices: salesInvoices } }));
        }
      },
    });
  }
}
