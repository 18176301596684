import { Controller } from "@hotwired/stimulus";
import { isVatConsistent } from "../../purchase_invoice/control_vat_consistency";
import { launchASweetAlert } from "../../components/init_sweet_alert";
import { STANDARD_VAT_RATE } from "../../utils/constants";
import { isStringTrue, toggleClass } from "../../utils/misc";
import { isInputFilled } from "../../react_components/design_system/input/utils";
import { handleHttpResponse } from "../../utils/http_utils";

export default class extends Controller {
  static targets = ["amount", "vat", "vatAlert"];

  toggleVatAlert(e) {
    const vatInput = e.target.value;
    toggleClass(this.vatAlertTarget, "hidden", isInputFilled(vatInput));
  }

  // TODO(David Michel): replace w/ a custom parsley validator if possible
  validateSubmission(e) {
    if (!isVatConsistent(this.vatTarget.value, this.amountTarget.value)) {
      e.preventDefault();
      e.stopImmediatePropagation();
      this.vatTarget.classList.add("border-red");
      launchASweetAlert({
        title: `Le montant de TVA renseigné semble incohérent car supérieur à ${STANDARD_VAT_RATE * 100}%.`,
      });
    }
  }

  purchaseUpdated(e) {
    const { isAttachmentTypeChanged, accountableId } = e.currentTarget.dataset;

    handleHttpResponse({
      payload: e.detail[0],
      onSuccess: (response) => {
        if (isStringTrue(isAttachmentTypeChanged)) {
          document.dispatchEvent(
            new CustomEvent("accountable:typeChanged", { detail: { deletedInvoiceId: accountableId } })
          );
        } else {
          const purchaseInvoices = JSON.parse(response).purchase_invoices;
          document.dispatchEvent(
            new CustomEvent("purchaseInvoice:updated", { detail: { purchaseInvoices: purchaseInvoices } })
          );
        }
      },
    });
  }
}
