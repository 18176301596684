/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "../../../vendor/assets/javascripts/parsley/parsley.min";
import "select2";
import "select2/dist/css/select2.css";
import "easy-autocomplete";
import "easy-autocomplete/dist/easy-autocomplete.css";
import "easy-autocomplete/dist/easy-autocomplete.themes.css";
import "datatables.net";
import "flatpickr/dist/flatpickr.min.css";
import "dropzone/dist/dropzone.css";
import "dropzone/dist/basic.css";
import "intl-tel-input/build/css/intlTelInput.css";
import "web-animations-js";

// sweetAlert
import { launchASweetAlert } from "../components/init_sweet_alert.js";
// layout scripts
import {
  hideWaitingImage,
  manageWaitingImageOnLoad,
  showWaitingImage,
} from "../components/manage_waiting_image_on_load";
import { changeTabOnClick, changeUrl, showTabContent } from "../components/main_layout_tabs/change_tab_on_click";
import { userAvatarFormSubmit } from "../components/logo_form";
// mobile
import { addBorderRadiusToLastVisibleTab, changeTab, showTabsDropdownOnClick } from "../components/mobile_layout";
import { dismissProfileFulfillmentMessage } from "../components/profile_fulfillment/dismiss_profile_fulfillment_message";
import { profileModalTabs } from "../components/profile_fulfillment/profile_modal_tabs";
import { manageInputsChanges } from "../components/profile_fulfillment/manage_inputs_changes";
import { toggleLegalExtraInfo } from "../components/profile_fulfillment/show_extra_legal_info_in_profile_fulfillment";
import { toggleMeTaxExtraInfo } from "../components/profile_fulfillment/show_extra_me_tax_infos";
import { addDisabledToDateInputsForMe } from "../components/profile_fulfillment/add_disabled_class_to_date_input_for_me";
import {
  changeThumbnailAtNewUpload,
  openFileUploadOnDocumentCardClick,
} from "../components/profile_fulfillment/document_card";
import { triggerAlertWhenClosingModal } from "../components/profile_fulfillment/trigger_alert_when_closing_modal";
import {
  refreshAllProgressBars,
  refreshModalAfterSubmit,
} from "../components/profile_fulfillment/refresh_after_submit";
import { manageTabOptionOnInput } from "../components/profile_fulfillment/manage_tab_option_on_input";
// payslip onboarding
import {
  displayAssociatedInputs,
  launchQuestions,
  leftArrow,
  payslipProgressionBar,
  removeActiveClassOnAllFromContainers,
  rightArrow,
  selectBankAccount,
  selectEmployee,
} from "../components/payslip_onboarding";
import { changeSocialBankAccount, toggleAcreDateWithAcreEligibility } from "../components/social_dashboard";
import { initCurrentUserSelect2 } from "../components/init_current_user_select2";
import { managePictoOnHover } from "../components/manage_picto_on_hover";
import { manageSelectedItemsPerPage } from "../components/manage_selected_items_per_page";
import { initSalesNewSelect2 } from "../components/init_sales_new_select2";
import { initSocialOnboardSelect2, initSocialSelect2 } from "../components/init_social_select2";
import { multiChoiceButtons } from "../components/multi_choice_buttons";
import { switchButton } from "../components/switch_button";
import { flashAlert, notifyCancel, notifyUpdate } from "../components/flash_alert";
import { unitAutocompleteSelect2, vatAutocompleteSelect2 } from "../components/autocomplete_select2";
import { dataTableFunctions } from "../datatables/datatable_functions";
import { initProblematicPurchasesDatatable } from "../datatables/problematic_purchases_datatable";
import { changeValueOfCell } from "../components/tables/change_value_of_cell";
import { changePageCounter } from "../components/tables/change_page_counter";
import { removeTableRow } from "../components/tables/remove_table_row";
import {
  validateFields,
  validateMainTotalsConsistency,
} from "../components/sales_invoices/manage_sales_invoice_validation_btn";
import { getSalesInvoiceDueDateInputValue, updateDueDate } from "../components/sales_invoices/compute_due_date";
import {
  initSalesEmailInputValidation,
  isSalesEmailInputValid,
} from "../components/sales_invoices/is_sales_email_input_valid";
import { preventEnterOnSocialOnboarding } from "../components/social/prevent_enter_on_social_onboarding";
import { purchaseInvoiceChannel } from "../channels/purchase_invoice_status_channel";
import { countrySelect2 } from "../components/customer/country_select2";
import { customerEditValidation } from "../components/customer/customer_edit_validation";
import { cancelEditOnShowCustomer } from "../components/customer/show_customer_cancel_edit";
import { manageImageHover } from "../components/manage_image_hover";
import { validateInformation } from "../onboarding/validate_information";
import { checkPasswordRequirement, initCheckPasswordCard } from "../onboarding/check_password_requirement";
import { checkPreviousBlockFilled } from "../profiles/check_previous_block_filled";
import { animateProgressBar, initFormContent, intOfCompletion, profileForms } from "../profiles/profile_forms";
import { manageConditionnalInputDisplay } from "../components/manage_conditionnal_input_display";
import { checkInputsBeforeCpaLetterCreation } from "../profiles/check_inputs_before_cpa_letter_creation";
import { initCardForm, initSEPAForm } from "../profiles/subscription_form";
import { manageCredentialModifications } from "../profiles/manage_credential_modifications";
// Profile file upload
import { displayFilePreview } from "../profiles/profile_file_upload.js";
// Profile select 2
import { initProfilesSelect2 } from "../profiles/init_profiles_select_2.js";
// Change subscription form
import { manageSubscriptionChangeForm } from "../profiles/manage_subscription_change_form";
// Basic questions modal after onboarding js below
import { changeStep, managePreviousStep } from "../dashboard/basic_questions_modal/change_step";
import { manageInputs, manageMonthYearInput } from "../dashboard/basic_questions_modal/manage_month_year_input";
import { manageBasicQuestionsFormSubmit } from "../dashboard/basic_questions_modal/manage_form_submit";
import { initBasicForm } from "../dashboard/basic_questions_modal/init_basic_form";
import { acsInit } from "../bank_accounts/anytime_connect";
// CREATION JS BEGIN
import { preCompleteCompanyAddress } from "../creation/precomplete_company_address";
import { submitFormOnOwnerShareholderNotComplete } from "../creation/submit_form_on_owner_shareholder_not_complete";
import { displayContentOnLoad } from "../components/display_content_on_load";
import { checkboxWithImage } from "../components/checkbox_with_image";
import { initAllInputValidation } from "../components/init_input_validation";
import { initProgressBarUpdate } from "../creation/update_progress_bar";
import "controllers";
import { closeModal, scrollToField } from "../utils/misc";

window.closeModal = closeModal;
window.scrollToField = scrollToField;

window.launchASweetAlert = launchASweetAlert;

manageWaitingImageOnLoad();
window.showWaitingImage = showWaitingImage;
window.hideWaitingImage = hideWaitingImage;
changeTabOnClick();
window.showTabContent = showTabContent;
window.changeUrl = changeUrl;
userAvatarFormSubmit();

showTabsDropdownOnClick();
addBorderRadiusToLastVisibleTab();
changeTab();

dismissProfileFulfillmentMessage();
profileModalTabs();
manageInputsChanges();
window.manageInputsChanges = manageInputsChanges;
toggleLegalExtraInfo();
window.toggleLegalExtraInfo = toggleLegalExtraInfo;
addDisabledToDateInputsForMe();
window.addDisabledToDateInputsForMe = addDisabledToDateInputsForMe;
toggleMeTaxExtraInfo();
window.toggleMeTaxExtraInfo = toggleMeTaxExtraInfo;
openFileUploadOnDocumentCardClick();
changeThumbnailAtNewUpload();
// window.addEventListener('load', (event) => {
triggerAlertWhenClosingModal();
// })
window.refreshModalAfterSubmit = refreshModalAfterSubmit;
window.refreshAllProgressBars = refreshAllProgressBars;
window.manageTabOptionOnInput = manageTabOptionOnInput;

window.leftArrow = leftArrow;
rightArrow();
window.rightArrow = rightArrow;
window.removeActiveClassOnAllFromContainers = removeActiveClassOnAllFromContainers;
payslipProgressionBar();
window.payslipProgressionBar = payslipProgressionBar;
window.selectBankAccount = selectBankAccount;
selectBankAccount();
selectEmployee();
window.displayAssociatedInputs = displayAssociatedInputs;
displayAssociatedInputs();
launchQuestions();

toggleAcreDateWithAcreEligibility();
window.toggleAcreDateWithAcreEligibility = toggleAcreDateWithAcreEligibility;
changeSocialBankAccount();
window.changeSocialBankAccount = changeSocialBankAccount;

window.initCurrentUserSelect2 = initCurrentUserSelect2;
initCurrentUserSelect2();

managePictoOnHover();
window.managePictoOnHover = managePictoOnHover;

manageSelectedItemsPerPage();

window.initSalesNewSelect2 = initSalesNewSelect2;

initSocialSelect2();
initSocialOnboardSelect2();
window.initSocialSelect2 = initSocialSelect2;
window.initSocialOnboardSelect2 = initSocialOnboardSelect2;

window.multiChoiceButtons = multiChoiceButtons;
multiChoiceButtons();

window.switchButton = switchButton;
switchButton();

window.flashAlert = flashAlert;
window.notifyCancel = notifyCancel;
window.notifyUpdate = notifyUpdate;

window.unitAutocompleteSelect2 = unitAutocompleteSelect2;
window.vatAutocompleteSelect2 = vatAutocompleteSelect2;

// START - Moving these from the asset pipeline to webpacker
window.dataTableFunctions = dataTableFunctions;

if (document.getElementById("problematic_purchases_table")) {
  initProblematicPurchasesDatatable();
}

// END - Moving these from the asset pipeline to webpacker
window.changeValueOfCell = changeValueOfCell;

window.changePageCounter = changePageCounter;

window.removeTableRow = removeTableRow;

window.validateFields = validateFields;
window.validateMainTotalsConsistency = validateMainTotalsConsistency;

window.updateDueDate = updateDueDate;
window.getSalesInvoiceDueDateInputValue = getSalesInvoiceDueDateInputValue;

const salesEmailInput = document.getElementById("sales_form_email");
if (salesEmailInput) {
  initSalesEmailInputValidation(salesEmailInput);
}
window.isSalesEmailInputValid = isSalesEmailInputValid;

preventEnterOnSocialOnboarding();

purchaseInvoiceChannel();

countrySelect2();

customerEditValidation();

cancelEditOnShowCustomer();

manageImageHover();
window.manageImageHover = manageImageHover;

validateInformation();

if (document.querySelector(".password-check-card")) {
  checkPasswordRequirement();
  window.checkPasswordRequirement = checkPasswordRequirement;
  window.initCheckPasswordCard = initCheckPasswordCard;
}

checkPreviousBlockFilled();
window.checkPreviousBlockFilled = checkPreviousBlockFilled;

profileForms();
window.initFormContent = initFormContent;
window.intOfCompletion = intOfCompletion;
window.animateProgressBar = animateProgressBar;

manageConditionnalInputDisplay();
window.manageConditionnalInputDisplay = manageConditionnalInputDisplay;

checkInputsBeforeCpaLetterCreation();
window.checkInputsBeforeCpaLetterCreation = checkInputsBeforeCpaLetterCreation;

window.initCardForm = initCardForm;
window.initSEPAForm = initSEPAForm;

manageCredentialModifications();
window.displayFilePreview = displayFilePreview;

initProfilesSelect2();
window.initProfilesSelect2 = initProfilesSelect2;

manageSubscriptionChangeForm();

window.changeStep = changeStep;
managePreviousStep();

manageMonthYearInput();
window.manageInputs = manageInputs;

manageBasicQuestionsFormSubmit();

initBasicForm();

// Trick to resize screen in mobile to take into account url bar
const basicQuestionsMobile = document.querySelector("#basic_questions_modal.mobile");
if (basicQuestionsMobile) {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);

  window.addEventListener("resize", () => {
    // We execute the same script as before
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  });
}

if (
  document.getElementById("banksCreateModal") ||
  document.querySelector(".bank-connection-choice") ||
  document.querySelector(".initial-bank-choice")
) {
  acsInit();
}

preCompleteCompanyAddress();
window.preCompleteCompanyAddress = preCompleteCompanyAddress;

submitFormOnOwnerShareholderNotComplete();
window.submitFormOnOwnerShareholderNotComplete = submitFormOnOwnerShareholderNotComplete;
// CREATION JS END
window.displayContentOnLoad = displayContentOnLoad;

if (document.querySelector(".checkbox-as-label.with-image")) {
  checkboxWithImage();
}

if (document.querySelector(".form-with-validation")) {
  const form = document.querySelector(".form-with-validation");
  initAllInputValidation(form);
}

if (document.querySelector(".creation-container")) {
  initProgressBarUpdate();
}
