import { Controller } from "@hotwired/stimulus";
import { closeSidePanel } from "../../utils/misc";
import { removeTableRow } from "../../components/tables/remove_table_row";

export default class extends Controller {
  connect() {
    document.addEventListener("accountable:typeChanged", (event) =>
      this.attachmentTypeChanged(event.detail.deletedInvoiceId)
    );
  }

  disconnect() {
    document.removeEventListener("accountable:typeChanged", (event) =>
      this.attachmentTypeChanged(event.detail.deletedInvoiceId)
    );
  }

  afterAjax(event) {
    this.attachmentTypeChanged(event.currentTarget.dataset.accountableId);
  }

  attachmentTypeChanged = (deletedInvoiceId) => {
    removeTableRow(deletedInvoiceId);
    closeSidePanel();
  };
}
